import React from 'react';
//import StickyFooter from './footer'
//import Image from 'material-ui-image'
import pic from '../Mazhar Fareed Resume.jpg'
function Main() {
	return (
		<img src={pic} alt="Reload page"/>
)
}

export default Main;
